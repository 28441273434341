import { render, staticRenderFns } from "./SignUpSuccess.vue?vue&type=template&id=46ea073c&scoped=true&"
import script from "./SignUpSuccess.vue?vue&type=script&lang=js&"
export * from "./SignUpSuccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ea073c",
  null
  
)

export default component.exports