<template>
  <div style="margin: 8rem auto">
  <div class="inner-content">
    <div class="mb-11">
      <slot name="icon"></slot>
    </div>
    <h3 class=" mb-4 main-title">{{title}}</h3>
    <p class="inner-title">{{innerTitle}}</p>
  </div>
  </div>
</template>

<script>
export default {
name: "Notification",
  props: {
  title: String,
    innerTitle: String

  }
}
</script>

<style scoped lang="scss">
.inner-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "DM Sans", serif;
  line-height: 31px;
  padding: 10px;

}
.main-title{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
}
.main-title{
  text-align: center;
  font-size: 20px;
  @media (min-width: 650px) {
    white-space: pre;
  }
}
.inner-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #828282;
  @media (min-width: 650px) {
    white-space: pre;
    font-size: 14px;

  }

}

</style>
