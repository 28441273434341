<template>
<div>
  <OnboardingHeader>

  </OnboardingHeader>
  <div class="lg:tw-mt-64">
    <Notification
        :inner-title="text"
        :title="title">
      <template v-slot:icon>
        <checked-icon/>
      </template>
    </Notification>
  </div>

</div>
</template>

<script>
import Notification from "@/components/reusables/Notification";
import CheckedIcon from "@/components/icons/checkedIcon";
import OnboardingHeader from "@/components/reusables/OnboardingHeader";
export default {
name: "SignUpSuccess",
  components: {OnboardingHeader, CheckedIcon, Notification},
  data: ()=> {
    return {
      title: "Account Created Successfully. \n Check your email to verify your account.",
      text: "A verification link has been sent to your registered email address."
    }
  }
}
</script>

<style scoped>

</style>
